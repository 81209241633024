export default function MatchFiltering (el) {
    const teamItems = el.querySelectorAll(".team-item");
    const seasonItems = el.querySelectorAll(".season-item");
    const matchesContainer = document.querySelector("#matches-container");
    const penalty = el.dataset.penalty ? el.dataset.penalty : 0;

    const seasonDisplays = document.querySelectorAll("#season-display");
    const teamDisplays = document.querySelectorAll("#team-display");
    const loadingIndicator = document.querySelector("#loading");
    const endpoint = el.dataset.endpoint || '/api/matches';

    teamItems.forEach(item => {
        item.addEventListener("click", function() {
            const selectedTeam = this.getAttribute("data-team");
            fetchData(selectedTeam, null);
            teamDisplays.forEach(display => {
                display.innerHTML = this.dataset.display;
            });
        });
    });

    seasonItems.forEach(item => {
        item.addEventListener("click", function() {
            const selectedSeason = this.getAttribute("data-season");
            fetchData(null, selectedSeason);
            seasonDisplays.forEach(display => {
                display.innerHTML = this.dataset.display;
            });
        });
    });

    function fetchData(selectedTeam, selectedSeason) {
        const store = document.getElementById("match-store");
        const url = new URL(endpoint, window.location.origin);
        
        if (!selectedTeam && store?.dataset.team) {
            selectedTeam = store.dataset.team;
        } else {
            store.dataset.team = selectedTeam;
        }
        
        if (!selectedSeason && store?.dataset.season) {
            selectedSeason = store.dataset.season;
        } else {
            store.dataset.season = selectedSeason;
        }
        
        const params = {
            penalty: penalty
        };
        
        if (selectedTeam) {
            params.team = selectedTeam;
        }
        
        if (selectedSeason) {
            params.season = selectedSeason;
        }
        
        url.search = new URLSearchParams(params).toString();
        
        console.log(url)
        
        matchesContainer.innerHTML = "";
        loadingIndicator.classList.remove("hidden");
        fetch(url)
        .then(response => response.text())
        .then(data => {
            matchesContainer.innerHTML = data;
            console.log('fetched')
            loadingIndicator.classList.add("hidden");
        });
    }
}