export default function Opposition(els) {
  const opposition = document.querySelector(".opposition-players");
  const bulls = document.querySelector(".bulls-players");
  els.forEach((el) =>
    el.addEventListener("click", () => {
      opposition.classList.toggle("hidden");
      bulls.classList.toggle("hidden");
    })
  );
}
