import "core-js/stable";
import "regenerator-runtime/runtime";
import Menu from "./js/Menu";
import "./app.scss";
import Gallery from "./js/Gallery";
import More from "./js/More";
import Overview from "./js/Overview";
import Form from "./js/Form";
import Headroom from "headroom.js";
import Popup from "./js/Popup";
import Animations from "./js/Animations";
import Video from "./js/video";
import Slider from "./js/Slider";
import Opposition from "./js/Opposition";
import Bulls from "./js/Bulls";
import MatchFiltering from './js/MatchFiltering';

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";
import lazySizes from "lazysizes";

Animations();
runActions();

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

function runActions() {
  action("Slider", Slider);
  action("Gallery", Gallery);
  action("Video", Video);
  action("Form", Form);
  action("MatchFiltering", MatchFiltering);
  Opposition(document.querySelectorAll(".show-opposition"));
  Bulls(document.querySelectorAll(".show-bulls"));
  Popup(document.querySelectorAll(".popup-btn"));
  Menu(document.querySelectorAll(".mob-nav-btn"));
  Overview(document.querySelectorAll(".more-overview-btn"));
  More(document.querySelectorAll(".more-btn"));
  const headroomElement = document.querySelector("#main-header");
  const headroom = new Headroom(headroomElement, {
    offset: 100,
  });
  headroom.init();
}

gsap.registerPlugin(ScrollTrigger);

// Only used in dev
if (module.hot) {
  module.hot.accept();
}

var headings = document.querySelectorAll(".accordion .left .accord-heading");
var contents = document.querySelectorAll(".accordion .right .accord-content");

headings.forEach(function (heading, index) {
  heading.addEventListener("click", function () {
    // Hide all contents
    contents.forEach(function (content) {
      content.classList.remove("active");
    });

    // Show the clicked content
    contents[index].classList.add("active");

    // Toggle active class on headings
    headings.forEach(function (h) {
      h.classList.remove("active");
    });
    heading.classList.add("active");
  });
});

// Get all dropdown menus
const dropdownMenus = document.querySelectorAll(".dropdown-menu");

// Add event listeners to each dropdown parent
dropdownMenus.forEach((dropdown) => {
  const parent = dropdown.parentNode;

  parent.addEventListener("mouseenter", () => {
    positionDropdown(parent, dropdown);
    dropdown.style.opacity = "1";
    dropdown.style.visibility = "visible";
  });

  parent.addEventListener("mouseleave", () => {
    dropdown.style.opacity = "0";
    dropdown.style.visibility = "hidden";
  });
});

// Function to position the dropdown menu
function positionDropdown(parent, dropdown) {
  const parentRect = parent.getBoundingClientRect();
  dropdown.style.top = `${parentRect.height}px`;
  dropdown.style.left = `0px`;
}

if (document.getElementById("days")) {
  var countdownElement = document.getElementById("countdown");
  var countdownData = countdownElement.getAttribute("data-countdown");
  var countDownDate = new Date(countdownData).getTime();

  var x = setInterval(function () {
    var now = new Date().getTime();
    var distance = countDownDate - now;

    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    document.getElementById("days").innerHTML = days
      .toString()
      .padStart(2, "0");
    document.getElementById("hours").innerHTML = hours
      .toString()
      .padStart(2, "0");
    document.getElementById("minutes").innerHTML = minutes
      .toString()
      .padStart(2, "0");
    document.getElementById("seconds").innerHTML = seconds
      .toString()
      .padStart(2, "0");

    if (distance < 0) {
      clearInterval(x);
      document.getElementById("countdownText").innerHTML = "";
    }
  }, 1000);
}

function inc(element) {
  let el = document.querySelector(`[name="${element}"]`);
  el.value = parseInt(el.value) + 1;
}

function dec(element) {
  let el = document.querySelector(`[name="${element}"]`);
  if (parseInt(el.value) > 0) {
    el.value = parseInt(el.value) - 1;
  }
}

document.addEventListener("DOMContentLoaded", function () {
  const scrollingText = document.getElementById("scrolling-text");

  function checkOverflow() {
    if (scrollingText) {
      if (scrollingText.scrollWidth > scrollingText.clientWidth) {
        scrollingText.classList.add("scrollable");
      } else {
        scrollingText.classList.remove("scrollable");
      }
    }
  }

  function handleResize() {
    checkOverflow();
  }

  window.addEventListener("resize", handleResize);
  checkOverflow();
});

// Function to check if a div is an orphan
function isOrphanDiv(div) {
  return !div.querySelector("div");
}

// Function to hide the last orphan div
function hideLastOrphanDiv() {
  if (instaContainer) {
    var allDivs = instaContainer.querySelectorAll("div");
    var lastOrphanDiv = null;

    // Loop through all 'div' elements within the 'insta-container'
    for (var i = allDivs.length - 1; i >= 0; i--) {
      var div = allDivs[i];
      if (isOrphanDiv(div)) {
        lastOrphanDiv = div;
        break;
      }
    }

    // Show or hide the last orphan 'div' based on the screen size
    if (lastOrphanDiv) {
      if (window.innerWidth <= 1024) {
        // Adjust the breakpoint as needed
        lastOrphanDiv.style.display = "none";
      } else {
        lastOrphanDiv.style.display = "";
      }
    }
  }
}

// Find the parent container with the class name 'insta-container'
var instaContainer = document.querySelector(".insta-container");

// Initial execution to hide orphan div
hideLastOrphanDiv();

// Listen for window resize events
window.addEventListener("resize", function () {
  hideLastOrphanDiv();
});
